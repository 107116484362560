<template>
  <div>
    <div class="mt-15 px-8" v-if="isLoad">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader type="paragraph" class="mt-3"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <template v-else>
      <v-col cols="10" sm="8" class="pa-0 mt-10 pt-10 mx-auto">
        <div class="font-bold text-2xl sm:text-4xl mt-5">{{ item.name }}</div>
        <div class="text-sm py-4">{{ item.lastUpdate }}</div>
        <img
          v-if="item.image.file_path"
          :src="baseUrlImage + item.image.file_path"
          @error="$event.target.src = 'https://admin.bpdlh.id/assets/empty.png'"
          width="100%"
          class="rounded-md mt-8 img-detail" />
        <div class="white grid justify-items-center px-0 pt-5">
          <v-col cols="12" class="pa-0">
            <div class="font-bold text-base sm:text-xl my-5 text-left">
              {{ item.name }}
            </div>
            <div class="text-left mb-10" v-html="item.desc"></div>
          </v-col>
        </div>
      </v-col>
      <div class="pb-5 white" v-if="programs.length > 0">
        <v-col cols="8" sm="6" class="block m-auto px-0">
          <div
            class="font-bold text-base sm:text-3xl mb-7 text-left"
            v-if="bahasa">
            Program
          </div>
          <div class="font-bold text-base sm:text-3xl mb-7 text-left" v-else>
            Programs
          </div>
          <slick ref="slick" :options="slickOptions" class="px-10">
            <div
              v-for="(program, idxProgram) in programs"
              :key="'Pro' + idxProgram"
              @click="goToProgram('program-detail', program.id)"
              class="cursor-pointer mb-3 text-left">
              <v-row>
                <v-col cols="auto" class="pt-4">
                  <img src="@/assets/images/icon_program.svg" width="80%" />
                </v-col>
                <v-col cols="10">
                  <div class="font-bold text-xl py-0">{{ program.name }}</div>
                  <div class="text-sm py-3">{{ program.createdDate }}</div>
                  <!-- <div class="font-lora text-base">IDR {{ program.total_fund }}</div> -->
                </v-col>
              </v-row>
            </div>
          </slick>
          <button class="btn-white-outlined nextProgram-btn">
            <v-icon color="black">arrow_back</v-icon>
          </button>
          <button class="btn-white-outlined backProgram-btn">
            <v-icon color="black">arrow_forward</v-icon>
          </button>
        </v-col>
      </div>
      <div
        class="grey lighten-4 px-0 pt-10 pb-5 mt-5"
        v-if="stories.length > 0">
        <v-col cols="12" sm="10" class="block m-auto px-0">
          <div class="font-bold text-base sm:text-xl mb-7 text-center">
            <span v-if="bahasa">Kisah & Blog</span>
            <span v-else>Stories & Blogs</span>
          </div>
          <slick ref="slick" :options="slickOptionsStory" class="px-5">
            <div
              v-for="(i, index) in stories"
              :key="index"
              @click="goTo('content-detail', i.id, i.category.toLowerCase())"
              class="cursor-pointer mb-3 pa-5 text-left">
              <v-img
                :src="require('@/assets/images/cover_.png')"
                class="rounded align-center pa-5"
                height="250">
                <v-col
                  cols="12"
                  class="rounded-md border border-white-500 pa-5 bg-blur text-white">
                  <div class="text-sm">{{ i.category || "-" }}</div>
                  <div class="text-lg sm:text-xl tracking-wide py-2">
                    {{ strLimit(i.title, 35) || "-" }}
                  </div>
                  <v-btn
                    class="btn-primary-white mt-3 font-bold text-capitalize">
                    <span v-if="bahasa">Baca selengkapnya</span>
                    <span v-else>Read More</span>
                  </v-btn>
                </v-col>
              </v-img>
            </div>
          </slick>
          <button class="btn-white-outlined nextStory-btn">
            <v-icon color="black">arrow_back</v-icon>
          </button>
          <button class="btn-white-outlined backStory-btn">
            <v-icon color="black">arrow_forward</v-icon>
          </button>
        </v-col>
      </div>
      <div
        class="grey lighten-4 grid justify-items-center px-0 py-10"
        v-if="news.length > 0">
        <v-col cols="10" lg="8" class="pa-0">
          <div class="font-bold text-base sm:text-xl mt-5 mb-10" v-if="bahasa">
            Berita
          </div>
          <div class="font-bold text-base sm:text-xl mt-5 mb-10" v-else>
            News
          </div>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              class="cursor-pointer mb-3 text-left"
              v-for="(berita, index) in news"
              :key="index + 'news'"
              @click="goToProgram('news-detail', berita.id)">
              <v-row>
                <v-col cols="3" class="px-0">
                  <img
                    :src="baseUrlImage + berita.image.file_path"
                    @error="
                      $event.target.src =
                        'https://admin.bpdlh.id/assets/empty.png'
                    "
                    width="100%"
                    class="rounded-md sm:px-3" />
                </v-col>
                <v-col cols="9">
                  <div class="font-bold text-base pt-0 sm:text-xl">
                    {{ berita.title || "-" }}
                  </div>
                  <div class="text-sm py-2 pa-0">{{ berita.date || "-" }}</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <div
        class="grey lighten-4 grid justify-items-center px-0 pb-5 pt-10"
        v-if="windows.length > 0">
        <v-col cols="10" class="pa-0">
          <div class="font-bold text-base sm:text-xl mt-5 mb-10" v-if="bahasa">
            Windows
          </div>
          <div class="font-bold text-base sm:text-xl mt-5 mb-10" v-else>
            Windows
          </div>
          <v-row v-if="windows.length > 0">
            <v-col
              cols="12"
              sm="6"
              class="cursor-pointer mb-3 text-left"
              v-for="(window, idxWindow) in windows"
              :key="'wind' + idxWindow"
              @click="goToProgram('donor-detail', window.id)">
              <v-row>
                <v-col cols="3" v-if="window.image">
                  <img
                    :src="baseUrlImage + window.image.file_path"
                    @error="
                      $event.target.src =
                        'https://admin.bpdlh.id/assets/empty.png'
                    "
                    width="100%" />
                </v-col>
                <v-col cols="3" v-else>
                  <img src="@/assets/images/icon_donor.png" width="100%" />
                </v-col>
                <v-col cols="9">
                  <div class="font-bold text-lg py-0">{{ window.name }}</div>
                  <div
                    class="py-3"
                    v-html="strLimit(window.desc || '-', 90)"></div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </template>
  </div>
</template>
<script>
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: false,
    },
  },
  components: {
    Slick,
  },
  data() {
    return {
      slickOptions: {
        slidesToShow: 2,
        prevArrow: ".nextProgram-btn",
        nextArrow: ".backProgram-btn",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      slickOptionsStory: {
        slidesToShow: 3,
        prevArrow: ".nextStory-btn",
        nextArrow: ".backStory-btn",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
      item: {},
      programs: [],
      windows: [],
      news: [],
      stories: [],
      isLoad: false,
    };
  },
  watch: {
    bahasa() {
      this.getData();
    },
  },
  computed: {
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await this.getSector();
      // await this.getWindow();
      // await this.getNews();
    },
    async getSector() {
      this.isLoad = true;
      let param = {
        lang: this.language,
      };
      await axios
        .get(this.baseUrl + "/sector/" + this.id, { params: param })
        .then((response) => {
          if (response.data.code === 200) {
            this.item = response.data.data;
            this.programs = response.data.data.program.map((item) => {
              return {
                ...item,
                total_fund: item.donor.reduce((prevValue, item) => {
                  prevValue = +prevValue + +item.fund;
                  return prevValue;
                }, 0),
              };
            });
            let windows = this.programs.map((item) => {
              return item.donor;
            });
            this.windows = windows.flat();
            this.stories = response.data.data.news.filter((item) => {
              return item.category === "Blog" || item.category === "Kisah";
            });
            this.news = response.data.data.news.filter((item) => {
              return item.category === "News" || item.category === "Berita";
            });
          }
          this.isLoad = false;
        })
        .catch((error) => {
          if (error.response.data.code === 404) {
            this.$router.push({ name: "NotFound" });
          } else {
            this.isLoad = false;
            Swal.fire("Failed!", error.response.data.message, "error");
          }
        })
        .finally(() => (this.isLoad = false));
    },
    goToProgram(link, param) {
      this.$router.push({
        name: link,
        params: {
          id: param,
        },
      });
    },
    goTo(link, param, type) {
      this.$router.push({
        name: link,
        params: {
          id: param,
          type: "/" + type,
        },
      });
    },
  },
};
</script>
